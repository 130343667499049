define("ember-svg-jar/inlined/forms-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "\n<path d=\"M4.22222 2C3.00771 2 2 3.00771 2 4.22222V19.7778C2 20.9923 3.00771 22 4.22222 22H19.7778C20.9923 22 22 20.9923 22 19.7778V4.22222C22 3.00771 20.9923 2 19.7778 2H4.22222ZM4.22222 6.44444H19.7778V19.7778H4.22222V6.44444ZM7 9.77778C6.69333 9.77778 6.44444 10.0267 6.44444 10.3333V11.4444C6.44444 11.7511 6.69333 12 7 12H8.11111C8.41778 12 8.66667 11.7511 8.66667 11.4444V10.3333C8.66667 10.0267 8.41778 9.77778 8.11111 9.77778H7ZM11.4444 9.77778C11.1378 9.77778 10.8889 10.0267 10.8889 10.3333V11.4444C10.8889 11.7511 11.1378 12 11.4444 12H17C17.3067 12 17.5556 11.7511 17.5556 11.4444V10.3333C17.5556 10.0267 17.3067 9.77778 17 9.77778H11.4444ZM7 14.2222C6.69333 14.2222 6.44444 14.4711 6.44444 14.7778V15.8889C6.44444 16.1956 6.69333 16.4444 7 16.4444H8.11111C8.41778 16.4444 8.66667 16.1956 8.66667 15.8889V14.7778C8.66667 14.4711 8.41778 14.2222 8.11111 14.2222H7ZM11.4444 14.2222C11.1378 14.2222 10.8889 14.4711 10.8889 14.7778V15.8889C10.8889 16.1956 11.1378 16.4444 11.4444 16.4444H17C17.3067 16.4444 17.5556 16.1956 17.5556 15.8889V14.7778C17.5556 14.4711 17.3067 14.2222 17 14.2222H11.4444Z\" fill=\"#4B5565\"/>\n",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});