define("ember-svg-jar/inlined/cost-plus-remove-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "\n<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M12.5906 13.5123L10.0688 16.05C9.93125 16.2 9.76875 16.3125 9.58125 16.3875C9.39375 16.4625 9.2 16.5 9 16.5C8.8 16.5 8.6125 16.4625 8.4375 16.3875C8.2625 16.3125 8.1 16.2 7.95 16.05L1.96875 10.05C1.83125 9.9135 1.71875 9.75425 1.63125 9.57225C1.54375 9.39025 1.5 9.1995 1.5 9C1.5 8.8005 1.54375 8.61 1.63125 8.4285C1.71875 8.247 1.83125 8.08125 1.96875 7.93125L4.48525 5.40686L2.214 3.13561L3.27466 2.07495L16.0026 14.8029L14.9419 15.8635L12.5906 13.5123ZM9.75 10.6716V12H8.25V9.75H6V8.25H7.32839L9.75 10.6716Z\" fill=\"#121926\"/>\n<path d=\"M12 8.25H10.597L9.75 7.40295V6H8.34705L6.11702 3.76998L7.95 1.93125C8.087 1.78075 8.24625 1.67125 8.42775 1.60275C8.60925 1.53425 8.8 1.5 9 1.5C9.2 1.5 9.39375 1.5375 9.58125 1.6125C9.76875 1.6875 9.93125 1.79375 10.0688 1.93125L16.0312 7.93125C16.1817 8.08075 16.2975 8.24625 16.3785 8.42775C16.4595 8.60925 16.5 8.8 16.5 9C16.5 9.2 16.4563 9.3875 16.3688 9.5625C16.2812 9.7375 16.1687 9.9 16.0312 10.05L14.2198 11.8728L12 9.65295V8.25Z\" fill=\"#121926\"/>\n",
    "attrs": {
      "width": "18",
      "height": "18",
      "viewBox": "0 0 18 18",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});