define("ember-svg-jar/inlined/mark-email-read", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "\n<path d=\"M7.66667 12C7.66667 9.42 9.75333 7.33333 12.3333 7.33333C13.0533 7.33333 13.7267 7.5 14.3333 7.78667V3.33333C14.3333 2.6 13.7333 2 13 2H2.33333C1.6 2 1 2.6 1 3.33333V11.3333C1 12.0667 1.6 12.6667 2.33333 12.6667H7.72C7.68667 12.4467 7.66667 12.2267 7.66667 12ZM2.33333 3.33333L7.66667 6.66667L13 3.33333V4.66667L7.66667 8L2.33333 4.66667V3.33333ZM11.2267 14L8.86667 11.64L9.80667 10.7L11.22 12.1133L14.0467 9.28667L15 10.2267L11.2267 14Z\" fill=\"#364152\"/>\n",
    "attrs": {
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});