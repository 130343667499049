define("ember-svg-jar/inlined/cost-plus-add-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "\n<path d=\"M8.25 12H9.75V9.75H12V8.25H9.75V6H8.25V8.25H6V9.75H8.25V12ZM9 16.5C8.8 16.5 8.60925 16.4658 8.42775 16.3973C8.24625 16.3288 8.087 16.2192 7.95 16.0687L1.96875 10.0688C1.83125 9.91875 1.71875 9.753 1.63125 9.5715C1.54375 9.39 1.5 9.1995 1.5 9C1.5 8.8005 1.54375 8.60975 1.63125 8.42775C1.71875 8.24575 1.83125 8.0865 1.96875 7.95L7.95 1.95C8.1 1.8 8.2625 1.6875 8.4375 1.6125C8.6125 1.5375 8.8 1.5 9 1.5C9.2 1.5 9.39375 1.5375 9.58125 1.6125C9.76875 1.6875 9.93125 1.8 10.0688 1.95L16.0312 7.95C16.1687 8.1 16.2813 8.2625 16.3688 8.4375C16.4563 8.6125 16.5 8.8 16.5 9C16.5 9.2 16.4595 9.39075 16.3785 9.57225C16.2975 9.75375 16.1818 9.91925 16.0312 10.0688L10.0688 16.0687C9.93125 16.2062 9.76875 16.3125 9.58125 16.3875C9.39375 16.4625 9.2 16.5 9 16.5Z\" fill=\"#1570EF\"/>\n",
    "attrs": {
      "width": "18",
      "height": "18",
      "viewBox": "0 0 18 18",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});